import { useQuery } from "@apollo/client";
import Fuse from "fuse.js";
import { slice, map, reduce, isEmpty, includes, filter } from "lodash";
import { useCallback, useMemo } from "react";

import { searchTags as searchTagsQuery } from "src/queries/tags.graphql";

export const addExtraInfoToTag = (targetTags, tagTypesConfig) => {
  return map(targetTags, (target) => {
    return {
      ...target,
      typeName: (tagTypesConfig[target.type] || {}).name || target.type,
    };
  });
};

const FuseSearchOptions = {
  includeMatches: true,
  matchAllTokens: true,
  findAllMatches: true,
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["displayValue"],
};

const useTagsSearch = ({
  tagTypesConfig,
  limit = 0,
  skip = false,
  visibleIn,
}) => {
  const [targetTypes, tagTypeIds] = useMemo(() => {
    const types = reduce(
      tagTypesConfig,
      (prev, config) => {
        // Make sure you query and bring only tag types filtered by visibleIn
        if (visibleIn && !includes(config?.visibleIn, visibleIn)) {
          return prev;
        }

        // Make sure tag type is visible in Directory and not group association
        // Group associations has a special penal in team details page
        if (!config.isGroupAssociation) {
          prev.push(config);
        }

        return prev;
      },
      []
    );

    return [types, map(types, "id")];
  }, [tagTypesConfig, visibleIn]);

  // Keep this useQuery in the component, so that cache updates will trigger a re-render
  const { data: tagsData, loading } = useQuery(searchTagsQuery, {
    variables: {
      tagTypes: tagTypeIds,
      includeInactiveTags: true,
    },
    skip: isEmpty(tagTypeIds) || skip,
  });

  const allTags = useMemo(() => {
    if (!tagsData) {
      return [];
    }

    return tagsData.tags || [];
  }, [tagsData]);

  const tagsFuse = useMemo(() => {
    const filteredTags = filter(allTags, (tag) => tag.isActive);
    return new Fuse(filteredTags, FuseSearchOptions);
  }, [allTags]);

  const getTags = useCallback(
    ({ terms, offset = 0 }) => {
      if (isEmpty(terms)) {
        return {
          targetTags: slice(allTags, 0, offset + limit),
          totalCount: allTags.length,
        };
      }
      const result = tagsFuse.search(terms);

      return {
        targetTags: map(
          limit > 0 ? slice(result, 0, offset + limit) : result,
          "item"
        ),
        totalCount: result.length,
      };
    },
    [allTags, limit, tagsFuse]
  );

  const searchTags = useCallback(
    async (terms, offset = 0) => {
      if (isEmpty(tagTypesConfig) || !tagsFuse) {
        return {
          totalCount: 0,
          tags: [],
        };
      }
      const { targetTags, totalCount } = getTags({ terms, offset });

      return {
        totalCount,
        tags: addExtraInfoToTag(targetTags, tagTypesConfig),
      };
    },
    [getTags, tagTypesConfig, tagsFuse]
  );

  return {
    isReady: !loading,
    targetTagTypes: targetTypes,
    tags: allTags,
    searchTags,
  };
};

export default useTagsSearch;
