import React, { useEffect } from "react";
import { get, sortBy, isEmpty } from "lodash";
import {
  H5,
  H6,
  Divider,
  Spacer,
  RadioButton,
  Checkbox,
  Badge,
} from "orcs-design-system";
import { useHistory } from "react-router-dom";
import { getSearchPath } from "src/util/paths";
import MatchPropType from "src/custom-prop-types/match";

import { useGlobalSearchContext } from "src/components/Search/GlobalSearchContext";
import { getGroupTypeBadgeVariant } from "src/util/group";
import {
  useWorkspace,
  useWorkspaceFeatureFlags,
} from "src/contexts/global/WorkspaceContext";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import usePrevious from "src/util/usePrevious";
import {
  filterTypeLabels,
  filterTypes,
} from "../../../pages/SearchPage/SearchPage.config";
import FilterSearchSection from "./FilterSearchSection";

const FilterSearchResults = ({ match }) => {
  const {
    searchQuery,
    defaultFilters,
    setDefaultFilters,
    defaultTeamTypes,
    setDefaultTeamTypes,
    addDefaultFilter,
    removeDefaultFilter,
    addDefaultTeamType,
    removeDefaultTeamType,
  } = useGlobalSearchContext();
  const featureFlags = useFeatureFlags();
  const isScopedSearchEnabled = get(
    featureFlags,
    "scoped-search-filter",
    false
  );

  const { params } = match;
  const { type } = params;
  const history = useHistory();
  const workspaceFeatureFlags = useWorkspaceFeatureFlags();
  const previouseSearchQuery = usePrevious(searchQuery);

  const onHandleNavigate = (searchType, query) => (e) => {
    setDefaultFilters([]);
    setDefaultTeamTypes([]);
    history.push(getSearchPath(searchType, query));
    e.stopPropagation();
    e.preventDefault();
  };

  const onHandleSubfilter = (e) => {
    if (e.target.checked) {
      addDefaultFilter(e.target.name);
    } else {
      removeDefaultFilter(e.target.name);
    }
  };

  const onHandleTeamType = (e) => {
    if (e.target.checked) {
      addDefaultTeamType(e.target.name);
    } else {
      removeDefaultTeamType(e.target.name);
    }
  };

  useEffect(() => {
    const url = getSearchPath(
      type,
      searchQuery,
      !(!!previouseSearchQuery && !searchQuery)
    );
    const searchParams = [];
    if (!isEmpty(defaultFilters)) {
      searchParams.push(`filters=${defaultFilters.join()}`);
    }

    if (!isEmpty(defaultTeamTypes)) {
      searchParams.push(`team_types=${defaultTeamTypes.join()}`);
    }

    const currentUrl = !isEmpty(searchParams)
      ? `${url}?${searchParams.join("&")}`
      : url;
    const prevPath = history?.location?.search
      ? `${history?.location?.pathname} + ${history?.location?.search}`
      : history?.location?.pathname;

    if (decodeURI(currentUrl) !== decodeURI(prevPath)) {
      history.push(currentUrl);
    }
  }, [
    defaultFilters,
    defaultTeamTypes,
    type,
    searchQuery,
    history,
    previouseSearchQuery,
  ]);

  useEffect(() => {
    setDefaultFilters([]);
    setDefaultTeamTypes([]);
  }, [type, setDefaultFilters, setDefaultTeamTypes]);

  const workspace = useWorkspace();

  return (
    <div id="keep-open-on-click-outside">
      <Spacer mb="r">
        <H5 fontWeight="bold">Search filters</H5>
        <Divider />
        <H6 fontWeight="bold">What are you looking for?</H6>
        <Spacer mr="r" mb="xs">
          <RadioButton
            name="search-filter-btn"
            label={filterTypeLabels.ALL}
            checked={type === filterTypes.ALL}
            onClick={(e) => {
              onHandleNavigate(filterTypes.ALL, searchQuery)(e);
            }}
            mt="s"
            mb="s"
          />
          <RadioButton
            name="search-filter-btn"
            label={filterTypeLabels.PEOPLE}
            checked={type === filterTypes.PEOPLE}
            onClick={(e) => {
              onHandleNavigate(filterTypes.PEOPLE, searchQuery)(e);
            }}
            mt="s"
            mb="s"
          />
          <RadioButton
            name="search-filter-btn"
            label={filterTypeLabels.TEAM}
            checked={type === filterTypes.TEAMS}
            onClick={(e) => {
              onHandleNavigate(filterTypes.TEAMS, searchQuery)(e);
            }}
            mt="s"
            mb="s"
          />
          {!workspaceFeatureFlags?.disableGlobalTagsSearch && (
            <RadioButton
              name="search-filter-btn"
              label={filterTypeLabels.TAGS}
              checked={type === filterTypes.TAGS}
              onClick={(e) => {
                onHandleNavigate(filterTypes.TAGS, searchQuery)(e);
              }}
              mt="s"
              mb="s"
            />
          )}
        </Spacer>

        {isScopedSearchEnabled && type === filterTypes.PEOPLE && (
          <FilterSearchSection
            items={[
              { label: "First name", name: "firstName" },
              { label: "Last name", name: "surname" },
              { label: "Job Title", name: "jobTitle" },
            ]}
            onChange={onHandleSubfilter}
          />
        )}
        {isScopedSearchEnabled && type === filterTypes.TEAMS && (
          <Spacer mr="r" mt="s">
            <H6 fontWeight="bold">Filter results by team type:</H6>
            {sortBy(Object.values(workspace?.config?.groupTypes), "name").map(
              (groupType) => {
                return (
                  <Checkbox
                    key={groupType.id}
                    label={
                      <Badge variant={getGroupTypeBadgeVariant(groupType)}>
                        {groupType.name}
                      </Badge>
                    }
                    name={groupType.id}
                    onChange={onHandleTeamType}
                  />
                );
              }
            )}
          </Spacer>
        )}
      </Spacer>
    </div>
  );
};

FilterSearchResults.propTypes = {
  match: MatchPropType.isRequired,
};

export default FilterSearchResults;
