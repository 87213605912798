import React from "react";
import PropTypes from "prop-types";
import { H6, Checkbox, Small, Spacer } from "orcs-design-system";

const FilterSearchSection = (props) => {
  const { items, onChange } = props;

  return (
    <Spacer mr="r" mt="s">
      <H6 fontWeight="bold">Filter results by:</H6>
      <Small fontSize="1.3rem" color="greyDark" display="block">
        Selecting filters below will refine your search to only show results
        matching one (or more) of the following fields.
      </Small>
      {items &&
        items.map((item) => {
          return (
            <Checkbox
              key={item.name}
              label={item.label}
              name={item.name}
              onChange={onChange}
            />
          );
        })}
    </Spacer>
  );
};

FilterSearchSection.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FilterSearchSection;
