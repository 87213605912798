import { map } from "lodash";
import { Avatar, Flex, Spacer, StyledLink, Badge } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { OptionValuePropType } from "src/custom-prop-types/search";

import * as searchUtil from "src/util/search";
import { getGroupBadgeVariant } from "src/util/group";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import ActionButton from "./sub-components/ActionButton";
import Layout from "./sub-components/Layout";
import Placeholder from "./sub-components/Placeholder";

const CustomStyledLink = styled(StyledLink)`
  &:hover {
    text-decoration: none;
  }
`;

const OptionBadgeWithActions = ({
  data,
  isLoading,
  theme,
  showPopover,
  selectedString,
}) => {
  const groupTypes = useGroupTypes();

  if (isLoading) {
    return <Placeholder theme={theme} />;
  }

  const { actions, badge, link, person, CustomActionsComponent } = data;

  const subTitle = (
    <Flex flexWrap="wrap">
      <Badge mt="xs" style={{ "text-decoration": "none" }}>
        {searchUtil.highlightSelectedString(badge?.subtitle, selectedString)}
      </Badge>
      {map(searchUtil.teamsToRender(person, groupTypes), (item) => {
        return (
          <Badge
            key={item.id}
            ml="xs"
            mt="xs"
            variant={getGroupBadgeVariant(item, groupTypes)}
          >
            {searchUtil.highlightSelectedString(item.name, selectedString)}
          </Badge>
        );
      })}
    </Flex>
  );

  return (
    <Layout theme={theme} hideDivider={data.customStyles}>
      <CustomStyledLink
        aria-hidden="true"
        tabIndex="-1"
        data-testid="cp-option-link"
        to={link}
        display="block"
        width="100%"
      >
        <Avatar
          sizing="small"
          title={searchUtil.highlightSelectedString(
            badge?.title,
            selectedString
          )}
          subtitle={subTitle}
          initials={badge?.initials}
          image={badge?.image}
        />
      </CustomStyledLink>
      <Flex alignItems="center">
        {CustomActionsComponent ? (
          <CustomActionsComponent />
        ) : (
          <Spacer ml="s">
            {map(actions, (action, index) => (
              <ActionButton
                key={`action-btn-${index}`}
                callback={action.callback}
                isDisabled={action.isDisabled}
                icon={action.icon}
                iconSize={action.iconSize}
                text={action.text}
                width={action.width}
                showPopover={showPopover}
              />
            ))}
          </Spacer>
        )}
      </Flex>
    </Layout>
  );
};

OptionBadgeWithActions.propTypes = {
  data: OptionValuePropType,
  isLoading: PropTypes.bool,
  theme: PropTypes.string,
  showPopover: PropTypes.bool,
  selectedString: PropTypes.string,
};

export default OptionBadgeWithActions;
