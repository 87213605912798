import { isEmpty, last } from "lodash";
import { generatePath, matchPath } from "react-router";

import { getGroupType } from "src/util/groupType";

export const paths = {
  apiDocs: "/:workspaceId?/api-docs",
  workspaceConfiguration:
    "/:workspaceId?/configuration/:tabType/:actionType?/:integrationType?",
  datasource: "/:workspaceId?/configuration/datasource/:dataSourceId",

  createWorkspace: "/workspaces/create",
  teamDetailsTab: "/:workspaceId?/teams/:id/:tabType",
  search: "/:workspaceId?/search/:type/:searchTerm?",
  teams: "/:workspaceId?/teams",
  teamDetails: "/:workspaceId?/teams/:id",
  personDetails: "/:workspaceId?/people/:id",
  people: "/:workspaceId?/people",
  peopleTabs: "/:workspaceId?/people/:id/:tabType",
  tags: "/:workspaceId?/tags/:id",
  logout: "/:workspaceId?/logout",
  explore: "/:workspaceId?/explore",
  newUserLanding: "/start",
  home: "/:workspaceId?/home",
  summary: "/:workspaceId?/summary",
  workspaces: "/:workspaceId?/workspaces",
  appHome: "/:workspaceId?",

  // urls added so that navigation works with workspaceid in TF
  admin: "/:workspaceId?/admin/dashboard",
  adminDashboard: "/:workspaceId?/admin/dashboard/:tabType",
  internalDashboardGeneral: "/:workspaceId?/internal-dashboard/general",
  internalDashboard: "/:workspaceId?/internal-dashboard/:tabType",
  visiualisationWithId: "/:workspaceId?/explore/:pageType/:aggregateId",
  userHome: "/:workspaceId?/user/:tabType",
};

export const pathList = Object.values(paths);

export const matchPaths = (url) => {
  const routeMatch = matchPath(url || window.location.pathname, {
    path: pathList,
    exact: true,
    strict: true,
  });
  return routeMatch;
};

export const getWorkspaceIdFromUrl = () => matchPaths()?.params?.workspaceId;
export const getSearchTerm = () =>
  decodeURI(matchPaths()?.params?.searchTerm || "");

const getDecodedValue = (value) => {
  return value ? decodeURI(value) : null;
};

export const getSearchPath = (type, searchTerm, useFallbackFromUrl = true) => {
  const urlParams = matchPaths()?.params;
  return generatePath(paths.search, {
    type: type || urlParams?.type || "all",
    searchTerm:
      searchTerm ||
      (useFallbackFromUrl ? getDecodedValue(urlParams?.searchTerm) : null),
    workspaceId: urlParams?.workspaceId,
  });
};

export const getTeamsPath = (id) => {
  const urlParams = matchPaths()?.params;
  return generatePath(paths.teamDetails, {
    id: id || urlParams?.id,
    workspaceId: urlParams?.workspaceId,
  });
};

export const getPeoplePath = (id) => {
  const urlParams = matchPaths()?.params;
  return generatePath(paths.personDetails, {
    id: id || urlParams?.id,
    workspaceId: urlParams?.workspaceId,
  });
};
export const getTagsPath = (id) => {
  const urlParams = matchPaths()?.params;
  return generatePath(paths.tags, {
    id: id || urlParams?.id,
    workspaceId: urlParams?.workspaceId,
  });
};

export const getHomePath = () => {
  const urlParams = matchPaths()?.params;
  return generatePath(paths.home, {
    workspaceId: urlParams?.workspaceId,
  });
};

export const getExplorePath = (workspaceId) => {
  const urlParams = matchPaths()?.params;
  return generatePath(paths.explore, {
    workspaceId: workspaceId || urlParams?.workspaceId,
  });
};

export const getTeamsSearchPath = () => {
  const urlParams = matchPaths()?.params;
  return generatePath(paths.teams, {
    workspaceId: urlParams?.workspaceId,
  });
};

export const getExplicitExplorePath = (workspaceId) => {
  return generatePath(paths.explore, { workspaceId });
};

export const getPersonDetailsPath = (id) => {
  const urlParams = matchPaths()?.params;
  return generatePath(paths.personDetails, {
    id: id || urlParams?.id,
    workspaceId: urlParams?.workspaceId,
  });
};

export const navigateToWorkspace = (workspaceId) => {
  const newPath = getExplorePath(workspaceId);
  history.push(newPath);
};

export const isTeamPage = (currentPath) => paths.teamDetails === currentPath;

export const onNavigateUp = (group, history, groupTypes) => {
  const { parentIds } = group;
  const groupType = getGroupType(group, groupTypes);
  if (groupType.isRoot || isEmpty(parentIds)) {
    history.push(getExplorePath());
  } else {
    history.push(getTeamsPath(last(parentIds)));
  }
};
